import { Box, Card, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ResponsivePie } from '@nivo/pie';
import { useProfile } from 'api/profile';
import { Skeleton } from '@mui/material';
import { abbreaviateNumber, formatDataSize } from 'utils/number';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { styled } from '@mui/material/styles';
import { useBillingSso } from 'api/auth';
import { ProgressiveButton } from 'component/base/ProgressiveButton';
import { useSnackbar } from 'component/hooks/useSnackbar';

const PREFIX = 'DashboardMetrics';

const classes = {
  box: `${PREFIX}Box`,
  skeleton: `${PREFIX}Skeleton`,
  upgradeButton: `${PREFIX}UpgradeButton`,
};

const StyledGrid = styled(Grid)({
  [`& .${classes.upgradeButton}`]: {
    marginTop: '-5.625rem',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: 'white',
    },
    '& svg[data-icon=plus]': {
      marginRight: '0.313rem',
    },
  },
  [`& .${classes.skeleton}`]: {
    position: 'relative',
    top: '25px',
    margin: '0 auto',
  },
  [`& .${classes.box}`]: {
    height: '200px',
  },
});

type DashboardMetricProps = {
  readonly selected: number;
  readonly total: number;
  readonly label: string;
  readonly formatValue?: (val: number) => string;
  readonly totalValue?: string;
  readonly showUpgradeButton?: boolean;
  readonly packageId: number;
  readonly isEnterprice?: boolean;
};

const defaultFormatValue = (val: any) => abbreaviateNumber(val, 0);

function DashboardMetric({
  selected,
  total,
  label,
  formatValue = defaultFormatValue,
  totalValue,
  showUpgradeButton,
  packageId,
  isEnterprice,
}: DashboardMetricProps) {
  const { t } = useTranslation();
  const { mutateAsync: getBillingLink, isPending: isBillingLinkPending } = useBillingSso();
  const { enqueueSnackbar } = useSnackbar();

  const CenteredMetric = ({
    centerX,
    centerY,
  }: {
    readonly centerX: number;
    readonly centerY: number;
  }) => {
    return (
      <>
        <text
          x={centerX}
          y={centerY}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: '14px',
            fontWeight: 600,
            textTransform: 'uppercase',
          }}
          transform="translate(0 -30)"
        >
          {label}
        </text>
        <text
          x={centerX}
          y={centerY}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: '36px',
            fontWeight: 700,
          }}
        >
          {formatValue(selected).split(' ')[0]}
          <tspan
            dy={6}
            style={{
              fontSize: '18px',
              fontWeight: 600,
            }}
          >
            {' '}
            {formatValue(selected).split(' ')[1]}
          </tspan>
        </text>
        <text
          x={centerX}
          y={centerY}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: '14px',
            fontWeight: 300,
            fill: 'grey',
          }}
          transform="translate(0 30)"
        >
          {t('of_entity', { entity: totalValue ?? formatValue(total) })}
        </text>
      </>
    );
  };

  return (
    <>
      <ResponsivePie
        data={[
          {
            id: 'selected',
            label: 'Selected',
            value: selected,
            color: 'rgb(36, 167, 202)',
          },
          {
            id: 'available',
            label: 'Avaliable',
            value: total - selected,
            color: 'rgb(240, 240, 240)',
          },
        ]}
        margin={{ top: 24, right: 24, bottom: 32, left: 24 }}
        innerRadius={0.89}
        padAngle={0}
        cornerRadius={20}
        startAngle={-135}
        endAngle={135}
        enableArcLinkLabels={false}
        enableArcLabels={false}
        isInteractive={false}
        colors={{ datum: 'data.color' }}
        layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', CenteredMetric]}
      />
      {!isEnterprice &&
      (showUpgradeButton || (showUpgradeButton !== false && total <= selected)) ? (
        <Box textAlign="center">
          <ProgressiveButton
            className={classes.upgradeButton}
            variant="text"
            onClick={async () => {
              const { data } = await getBillingLink({
                destination: 'upgrade.php',
                params: `type=package&id=${packageId}`,
              });
              if (data.result?.link) {
                window.location.href = data.result?.link;
              } else {
                enqueueSnackbar(null, { variant: 'error' });
              }
            }}
            isLoading={isBillingLinkPending}
            color="primary"
          >
            <FontAwesomeIcon icon={faPlus} />
            {t('upgrade_plan')}
          </ProgressiveButton>
        </Box>
      ) : null}
    </>
  );
}

export function DashboardMetrics() {
  const { t } = useTranslation();
  const { isLoading, data } = useProfile();
  const result = data?.data.result;

  if (isLoading && !result) {
    return (
      <StyledGrid container spacing={3}>
        {Array.from({ length: 4 }).map((v, i) => (
          <Grid item xl={3} sm={6} xs={12} key={`loading-metric-${i}`}>
            <Card>
              <Box className={classes.box}>
                <Skeleton
                  className={classes.skeleton}
                  variant="circular"
                  width={150}
                  height={150}
                />
              </Box>
            </Card>
          </Grid>
        ))}
      </StyledGrid>
    );
  }

  return (
    <StyledGrid container spacing={3}>
      <Grid item xl={3} sm={6} xs={12} key="site-metric">
        <Card>
          <Box className={classes.box}>
            <DashboardMetric
              label={t('sites')}
              selected={result?.usage?.sites ?? 0}
              total={result?.limits?.sites ?? 0}
              packageId={result?.package?.package_id ?? 0}
              showUpgradeButton
              isEnterprice={result?.package?.plan === 'Enterprise'}
            />
          </Box>
        </Card>
      </Grid>
      <Grid item xl={3} sm={6} xs={12} key="visitors-metric">
        <Card>
          <Box className={classes.box}>
            {result?.limits?.visitors === 0 ? (
              <DashboardMetric
                label={t('visitors')}
                selected={result?.usage?.visitors ?? 0}
                total={result?.usage?.visitors ?? 0}
                totalValue={t('unlimited')}
                showUpgradeButton={false}
                packageId={result?.package?.package_id ?? 0}
                isEnterprice={result?.package?.plan === 'Enterprise'}
              />
            ) : (
              <DashboardMetric
                label={t('visitors')}
                selected={result?.usage?.visitors ?? 0}
                total={result?.limits?.visitors ?? 0}
                packageId={result?.package?.package_id ?? 0}
                isEnterprice={result?.package?.plan === 'Enterprise'}
              />
            )}
          </Box>
        </Card>
      </Grid>
      <Grid item xl={3} sm={6} xs={12} key="disk-usage-metric">
        <Card>
          <Box className={classes.box}>
            <DashboardMetric
              label={t('disk_usage')}
              selected={Number(result?.usage?.disk) ?? 0}
              total={Number(result?.limits?.disk) ?? 0}
              formatValue={v => formatDataSize(v, true, 0)}
              packageId={result?.package?.package_id ?? 0}
              isEnterprice={result?.package?.plan === 'Enterprise'}
            />
          </Box>
        </Card>
      </Grid>
      <Grid item xl={3} sm={6} xs={12} key="bandwidth-metric">
        <Card>
          <Box className={classes.box}>
            <DashboardMetric
              label={t('bandwidth')}
              selected={Number(result?.usage?.bandwidth) ?? 0}
              total={Number(result?.limits?.bandwidth) ?? 0}
              formatValue={v => formatDataSize(v, true, 1)}
              packageId={result?.package?.package_id ?? 0}
              isEnterprice={result?.package?.plan === 'Enterprise'}
            />
          </Box>
        </Card>
      </Grid>
    </StyledGrid>
  );
}
